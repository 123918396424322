import { useQuery } from '@apollo/client'
import { Select } from '@components'
import { Input } from '@elements'
import { golfClub as graphqlGolfClub, golfClubModel as graphqlGolfClubModel, golfClubType as graphqlGolfClubType, equipment as graphqlEquipment, user } from '@graphql'
import { useGraphqlGolfClub, useGraphqlGolfEquipment, useLocationWithState, useModal, useWindowSize, useGraphqlUser } from '@hooks'
import BackArrow from '@images/back-arrow.svg'
import PriceTagIcon from '@images/price-tag.svg'
import { CheckoutLayout } from '@layouts'
import {
  AddressCard,
  GolfClubModel,
  GolfClubType,
  GolfEquipmentModel,
  GolfEquipmentType,
  PaymentCard,
  ResponseType,
  User
} from '@types'
import { addDays, returnQueryFetchGolfTypes } from '@utils'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'

interface CheckoutSetSellPriceProps {
  id: string
}

const EXPIRATION_DAYS = [
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' }
]

const CheckoutSetSellPrice: React.FC<CheckoutSetSellPriceProps> = ({ id }) => {
  const { createGolfClub, loading: createGolfClubLoading } = useGraphqlGolfClub()
  const { createGolfEquipment } = useGraphqlGolfEquipment()
  const { fetchCurrentUser } = useGraphqlUser()

  const currentUser = fetchCurrentUser()
  const alert = useAlert()

  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { openModal: openSellerPaymentMethodWarningModal } = useModal('SellerPaymentMethodWarning')

  const { pathname, state } = useLocationWithState<{ viewPage: boolean, type: string }>()

  const isGolfClubPage = state.type === "GolfClubModel"

  /**
   * * isGolfClubPage === true - fetch golfClub Types
   * * isGolfClubPage === false - fetch golfEquipment Types
   */
  const golfItemTypeQuery = isGolfClubPage ? graphqlGolfClubType.FetchGolfClubType : graphqlEquipment.FetchGolfEquipmentType
  const { data: golfTypeData, loading: golfTypeLoading } = useQuery<ResponseType<GolfClubType | GolfEquipmentType>>(
    golfItemTypeQuery,
    { variables: { id }, fetchPolicy: 'network-only' }
  )

  const pathnameIndex = pathname.indexOf('set-sell-price')
  const checkoutSuccessValue = pathname.substring(pathnameIndex)
  const isBackPageView = state.viewPage
  const writtenPrice = isBackPageView ? 0 : parseInt(localStorage.getItem('writtenPrice') || '0')

  const [newGolfClubPrice, setNewGolfClubPrice] = useState<number | undefined>(writtenPrice)
  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)
  const [golfModel, setGolfModel] = useState<GolfEquipmentModel | GolfClubModel>()

  useEffect(() => {
    const isUserStripeAccountNotSubmitted =
      currentUser && currentUser.sellerStatus === 'SELLER' && !currentUser.stripeAccountSubmitted

    if (isUserStripeAccountNotSubmitted) {
      openSellerPaymentMethodWarningModal()
    }
  }, [currentUser])

  useEffect(() => {
    const golfType = golfTypeData?.res

    if (golfType) {
      if ('golfClubModel' in golfType) setGolfModel(golfType.golfClubModel)
      else if ('golfEquipmentModel' in golfType) setGolfModel(golfType.golfEquipmentModel)

      if (golfModel && golfModel.highestBuyPrice && !newGolfClubPrice) {
        setNewGolfClubPrice(golfModel.highestBuyPrice)
      }
    }
  }, [golfTypeData])

  const handleConfirm = async (addressCard?: AddressCard, userCard?: PaymentCard) => {
    const paymentCardId = userCard?.id
    const expirationDaysNumber = +selectedExpirationDays
    const expiresAt = addDays(new Date(), expirationDaysNumber).toISOString().split('T')[0]
    const golfClubTypeId = id
    const price = newGolfClubPrice
    const addressCardId = addressCard?.id

    if (addressCardId && expiresAt && golfTypeData && paymentCardId && price) {
      if (golfModel?.__typename === 'GolfClubModel') {
        await createGolfClub(
          {
            golfClubTypeId,
            price,
            expiresAt,
            addressCardId,
            paymentCardId
          },
          {
            refetchQueries: [
              {
                query: graphqlGolfClubModel.FetchGolfClubModel,
                variables: { id: golfModel?.id || '' }
              },
              {
                query: graphqlGolfClub.FetchPaginatedGolfClubs,
                variables: {
                  pagination: { page: 1, pageSize: 25 }
                }
              }
            ]
          }
        )
      } else if (golfModel?.__typename === 'GolfEquipmentModel') {
        await createGolfEquipment({
          golfEquipment: {
            addressCardId: addressCardId,
            expiresAt: expiresAt,
            golfEquipmentTypeId: golfTypeData?.res.id,
            paymentCardId: paymentCardId,
            price: price
          }
        })
      }

      await navigate('/checkout-success', {
        state: {
          submitTitle: 'to golf club inventory',
          submitUrl: '/account/store/my-golf-club-inventory',
          isSell: true,
          checkoutSuccessValue: checkoutSuccessValue
        }
      })
    } else {
      const allParams: any = {
        golfClubTypeId,
        price,
        expiresAt,
        addressCardId,
        paymentCardId
      }

      const missParam: string[] = []

      for (const param in allParams) {
        if (!allParams[param]) {
          missParam.push(param)
        }
      }
      alert.show(`Error, these parameters : ${missParam.toString()} missing!`, {
        type: 'error'
      })
    }
  }

  return (
    <CheckoutLayout
      handleConfirm={handleConfirm}
      price={newGolfClubPrice}
      golfTypeData={golfTypeData}
      golfTypeLoading={golfTypeLoading}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PriceTagIcon />
          Set Sell Price
        </strong>
      }
      mutationLoading={createGolfClubLoading}
    >
      <div className="checkout-payment-info-set-price-form">
        <div className="checkout-payment-info-set-price-form-field">
          <label>price</label>
          <Input
            placeholder="$0.00"
            value={newGolfClubPrice?.toString()}
            onChange={val => {
              if (+val < 1000000) {
                setNewGolfClubPrice(+val)
              }
            }}
            type="text"
            theme="green"
          />
          <div className={'checkout-payment-info-set-price-form-field-warn'}>
            *You must meet the minimum offer of $15
          </div>
        </div>
        <div className="checkout-payment-info-set-price-form-field">
          <label>expiration</label>
          <Select
            name="expiration"
            value={selectedExpirationDays}
            options={EXPIRATION_DAYS}
            onChange={val => val && setSelectedExpirationDays(val)}
          />
        </div>
      </div>
    </CheckoutLayout>
  )
}

export default CheckoutSetSellPrice
